.container {
  width: 450px;
  height: 300px;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid #1d1d1d;
  cursor: pointer;
  flex-basis: 40%;
}
.insideContainer {
  width: 420px;
  height: 270px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border: 5px solid #1d1d1d;
  justify-content: center;
}
.logo {
  position: absolute;
  width: 100px;
  top: 10px;
  left: 10px;
}
.medal {
  position: absolute;
  width: 100px;
  top: -30px;
  right: 0px;
}
.title {
  font-size: 25px;
  margin-top: 100px;
  width: 90%;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  font-weight: bold;
  font-size: 25px;
  text-align: center;
}
.period {
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 20px;
  font-size: 16px;
  font-weight: bold;
}
.certificate {
  width: 40%;
}
