.iframeContainer {
    width: 100%;
    height: 100%;
    padding: 49px 57px 47px 50px;
  }
  
  
  .Close {
    position: absolute;
    right: 0;
    top: 0;
    margin: 18px 18px;
  
    img {
      width: 16px;
      height: 16px;
      object-fit: contain;
    }
  }