.coursesContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 75vw;
  width: 100%;
  z-index: 99;
  margin: 0 auto;
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    margin-block-start: 2em;

    margin-block-end: 0.3em;
    color: #29323c;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;
  }
}
.tabContainer {
  display: flex;
  flex-direction: row;
  margin: 20px auto 50px auto;
  max-width: 1240px;
}
.tab {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d5d5d5;
  border-radius: 20px;
  margin: 5px;
  height: 30px;
  padding: 5px 15px;
  width: 100%;
  span {
    color: #2b3543;
    font-weight: 600;
  }
}

.coursesWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  flex-basis: 30%;
  align-items: flex-start;
  justify-content: center;
  margin-top: 30px;
}
.courseCardContainer {
  margin: 10px;
  border-radius: 20px;
  width: 30%;
  height: 100%;
  z-index: 99;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.cardPrice {
  background: #e9f1fe;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */

    text-align: center;

    color: #29323c;
  }
}
.cardContent {
  ul {
    margin: 20px;

    text-align: start;
  }
  li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    /* or 144% */

    color: #485870;
  }
}
.cardButtton {
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 54px;
  border-radius: 15px;
  border: 1px solid #3d53eb;
  cursor: pointer;
  span {
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: #3d53eb;
  }
}
.cardHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
  width: 90%;
  h3 {
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-block-end: 0.3em;
    color: #29323c;
  }
}
.desc {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  margin-top: 6px;

  color: #868686;
}
.hours {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  margin-top: 8px;
  color: #3d53eb;
}
.stars {
  display: flex;
  height: 14px;
  margin-block-end: 0.3em;
}
.allButton {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  margin-top: 20px;
  text-align: end;
  justify-self: flex-end;
  align-self: flex-end;
  color: #29323c;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .tabContainer {
    display: none;
  }
  .coursesWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .courseCardContainer {
    margin: 10px;
    border-radius: 20px;
    width: 90vw;
    height: 100%;
    z-index: 99;
    background: #fff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .coursesContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 90vw;
    width: 100%;
    z-index: 99;
    margin: 0 auto;
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 52px;
      line-height: 62px;
      text-align: center;
      margin-block-start: 2em;

      margin-block-end: 0.3em;
      color: #29323c;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* or 150% */

      text-align: center;
    }
  }

  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 20px;
    margin: 5px;
    height: 30px;
    padding: 5px 15px;
    span {
      color: #2b3543;
      font-weight: 600;
    }
  }
}
