.Container {
  width: 100%;
  padding: 32px;
  .datePicker {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3f4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #e4e6ef;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .datePickerLabel {
    font-size: 1rem;
    font-weight: 400;
    color: #3f4254;
    margin-top: 1rem;
  }

  .backIconContainer {
    cursor: pointer;
    display: flex;
    margin-bottom: 24px;
    height: 17px;
    align-items: center;

    .backIconLabel {
      display: flex;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3196f6;
    }

    .backIcon {
      display: flex;
      margin-right: 8px;
    }
  }

  .Heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 30px;
    color: #3e5e6e;
  }

  .Paragraph {
    font-size: 14px;
    line-height: 1.71;
    margin-bottom: 32px;
    color: #3e5e6e;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .CheckboxList {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    .CheckboxGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 200px;
      justify-content: space-between;
    }
  }

  .EmailGroup {
    display: flex;
    flex-direction: column;

    .EmailGroupTitle {
      font-size: 14px;
      font-weight: bold;
      color: #0f3649;
    }

    .emailList {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: nowrap;
      z-index: 0;

      .removeEmailContainer {
        cursor: pointer;
        display: flex;
        justify-content: flex-end;
        right: 32px;
        height: 17px;
        align-items: center;

        .removeIcon {
          display: flex;
          margin-right: 2px;
          margin-bottom: 8px;
        }
      }
      .emailInput {
        width: 100%;
        z-index: 0;
      }
      .inputMargin {
        margin-right: 24px;
      }
    }

    .newEmailContainer {
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 8px;
      height: 17px;
      align-items: center;
      right: 32px;

      .plusIconLabel {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        color: #3196f6;
        margin-top: 20px;
        justify-content: center;
        width: 100%;
      }

      .plusIcon {
        display: flex;
        margin-right: 2px;
      }
    }
    .newEmailMargin {
      margin-right: 24px;
    }
  }

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin: 32px 32px;
  }
}
