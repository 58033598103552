.Container {
  width: 100%;
  padding: 32px;

  .backIconContainer {
    cursor: pointer;
    display: flex;
    margin-bottom: 24px;
    height: 17px;
    align-items: center;

    .backIconLabel {
      display: flex;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3196f6;
    }

    .backIcon {
      display: flex;
      margin-right: 8px;
    }
  }

  .Heading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    margin-top: 30px;
    color: #3e5e6e;
  }

  .Paragraph {
    font-size: 14px;
    line-height: 1.71;
    font-weight: 500;
    margin-bottom: 32px;
    color: #3e5e6e;
  }
}
.schedulerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 44px;
  width: 25%;
  height: 75px;
  background-color: #537a5a;
  margin: 0 auto;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 37.5%;
  -webkit-box-shadow: 9px 67px 96px -3px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 9px 67px 96px -3px rgba(0, 0, 0, 0.28);
  box-shadow: 9px 67px 96px -3px rgba(0, 0, 0, 0.28);
}
.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90px;
}
.timeLabel {
  display: flex;
  font-size: 40px;
  font-weight: bold;
  color: white;
}
.timeIcon {
  display: flex;
  margin-right: 4px;
}
