.karneContainer {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.container {
  width: 99%;
  height: 100%;
  min-height: 90vh;
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid #1d1d1d;
  cursor: pointer;
}
.insideContainer {
  width: 99%;
  height: 99%;
  margin: 10px;
  background-color: white;
  border-radius: 10px;
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border: 5px solid #1d1d1d;
  justify-content: center;
}
.medalIcon {
  width: 150px;
  position: absolute;
  top: -75px;
  right: 0px;
}
.logo {
  position: relative;
  display: flex;
  width: 75px;
  margin: 20px;
}
