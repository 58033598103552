.examContainer {
  width: 90%;
  height: auto;
  padding: 30px;
  margin: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  position: relative;
}

.Heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
  position: absolute;
  left: -10px;
  top: -10px;
  width: 40px;
  height: 40px;
  border-radius: 10px 0px 10px 0px;
  background-color: #3e5e6e;
  padding: 20px;
}

.QuestionText {
  font-size: 16px;
  margin-bottom: 8px;
  color: #3e5e6e;
}

.Paragraph {
  font-size: 14px;
  line-height: 1.71;
  margin-bottom: 32px;
  color: #3e5e6e;
  margin-bottom: 10px;
  margin-top: 10px;
}
